@charset "utf-8";

@import "bootstrap/scss/bootstrap";
@import "@fortawesome/fontawesome-free/scss/fontawesome";
@import "@fortawesome/fontawesome-free/scss/brands";
@import "@fortawesome/fontawesome-free/scss/regular";
@import "@fortawesome/fontawesome-free/scss/solid";
@import "typography";
@import "navbar";
@import "footer";
@import "components";
@import "carousel";
@import "styles";
@import "variables";

