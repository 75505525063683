.navbar-container 
{
  /* background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%); */
  border-style: solid; 
  border-width: 8px 0px 8px 0px; 
  border-color: #000000;

/* nav is not being used */
  nav {
  	font-size: 30px;
	}
}