footer {
  background-color: #0B2A75; 
  color: #FFFFFF;
  border-style: solid; 
  border-width: 8px 0px 0px 0px; 
  border-color: #FFFFFF;

  a {
  	color: #FFFFFF;
  }
}