body {
 text-align: center;
}

.banner-container {
	background-color: #0B2A75; 
	color: #FFFFFF;

	h5 {
		color: #F38134;
	}
}

.jumbotron {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/jumbotron.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  /* height: 100vh; */

	&:after {
  		content: '';
  		position: absolute;
  		top: 0;
  		left: 0;
  		width: 100%;
  		height: 100%;
  		background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%);
  		opacity: 0.8;
  		z-index: 1;
	}

	.jumbotron-container {
  		position: relative;
  		z-index: 10;
  		/*text-shadow: 0px 2px 3px #555;*/
  		/*color: white; */
	}
}

.main-content-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/main-cover.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  color: white;
  border-style: solid; 
  border-width: 8px 0px 0px 0px; 
  border-color: #FFFFFF;
  
	&:after {
  		content: '';
  		position: absolute;
  		top: 0;
  		left: 0;
  		width: 100%;
  		height: 100%;
  		background-image: linear-gradient(to top, #1e3c72 0%, #1e3c72 1%, #2a5298 100%);
  		opacity: 0.8;
  		z-index: 1;
	}

	.main-content {
  		position: relative;
  		z-index: 10;
	}

  .action-box-1 {
    /* style="border-style: solid; border-width: 8px 4px 8px 0px; border-color: #FFFFFF" */
    border-style: solid; 
    border-width: 8px 4px 8px 0px; 
    border-color: #FFFFFF;
  }

  .action-box-2 {
    /* style="border-style: solid; border-width: 8px 4px 8px 4px; border-color: #FFFFFF" */
    border-style: solid; 
    border-width: 8px 4px 8px 4px; 
    border-color: #FFFFFF;
  }

  .action-box-3 {
    /* style="border-style: solid; border-width: 8px 0px 8px 4px; border-color: #FFFFFF" */
    border-style: solid; 
    border-width: 8px 0px 8px 4px; 
    border-color: #FFFFFF;
  }
}

.main-content-form {
   border-width: 0px 0px 0px 0px;
}


.call-to-action {
  position: relative;
  width: 100%;
  height: 100%;
  background-image: url("/assets/images/san-diego.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-style: solid; 
  border-width: 0px 0px 8px 0px; 
  border-color: #FFFFFF;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(to right, #6a11cb 0%, #2575fc 100%);
    opacity: 0.6;
  }

  .action-content {
    position: relative;
    z-index: 10;
    color: #FFFFFF;
  }
}

.testimonial-container {
  background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
  border-style: solid; 
  border-width: 0px 0px 8px 0px; 
  border-color: #FFFFFF;


  blockquote {
    position: relative;
    display: inline-block;
    background: #eee;
    padding: 40px 30px 30px 60px;
    border-radius: 10px;
    /* font-family: 'Bitter', serif; */
    color: #333;
    text-align: left;
  
    box-shadow: 1px 1px 1px #999,
                2px 2px 1px #999,
                3px 3px 1px #999;
    
      &::before {
        display: block;
        content: "";
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        /* height: 1px; */
        background: #ccc;
        z-index: 1;
      }
    }
}

.about-container {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
  overflow: hidden;
}

.newsletter  {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}

.social-banner {
    border-style: solid; 
    border-width: 8px 0px 8px 0px; 
    border-color: #000000;
}

.blog-carousel {
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);

  .blog-slider {
    border-style: solid; 
    border-width: 8px 8px 8px 8px;
    border-color: #FFFFFF; 
  }
}

.map-responsive {
    overflow:hidden;
    padding-bottom:50%;
    position:relative;
    height:0;
}
.map-responsive iframe{
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
}

.blog-post {
  
  h5 {
    padding-bottom: 0.5rem !important;
    padding-top: 0.5rem !important;
  }
}

.staff-main {
  
}
.staff-post {
  
}

/* card post for blog and staff */
.card {
  background-image: linear-gradient(120deg, #fdfbfb 0%, #ebedee 100%);
}